/* eslint-disable compat/compat */
import { LitElement, html, css } from 'lit';

class ANav extends LitElement {
  static get properties() {
    return {
      href: String,
      title: String,
    };
  }

  static get styles() {
    return css`
            :host{
                display:block;
                min-height:1.5rem;
                cursor:pointer;
                color:var(--highlight-text-color);
            }
            a,a:visited,a:hover{
              text-decoration:none;
              color:inherit;
            }
            `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this._handleClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('click');
  }

  _handleClick(e) {
    e.preventDefault();
    if (!this.href) {
      if (window.history.length > 1) window.history.back();
      else window.history.pushState(null, null, '/');
    } else if (this.href.indexOf('http') !== 0 || this.href.indexOf('//') !== 0) {
      window.history.pushState(null, null, this.href);
      const route = this.href.replace(this.origin, '');
      this.dispatchEvent(new CustomEvent('route-changed', {
        bubbles: true,
        composed: true,
        detail: { href: route },
      }));
    }
    return false;
  }

  render() {
    return html`
        <a href="${this.href}" title="${this.title}"><slot></slot></a>
        `;
  }
}
customElements.define('a-nav', ANav);
